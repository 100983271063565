import { useContext, useState } from 'react';
import { createCallable } from 'react-call';
import * as api from '../api';
import { userAPI } from '../config';
import ErrorBox, { ErrorBoxType } from '../generic/ErrorBox';
import Modal from '../generic/Modal';
import SpinnerContainer from '../generic/SpinnerContainer';
import { UserContext } from '../login/userContext';
import useBooleanState from '../utils/useBooleanState';
import { useOnMount } from '../utils/useComponentDidMount';
import * as style from './ConfirmEmailModal.module.less';

const ConfirmEmailModal = createCallable(({ call }) => {
    const [error, setError] = useState<ErrorBoxType | null | undefined>(null);
    const [isLoading, setIsLoading] = useBooleanState();
    const [message, setMessage] = useState<string>();

    const { refreshUser, logout } = useContext(UserContext);
    useOnMount(({ refreshUser }) => {
        refreshUser();
    });

    const logOut = async () => {
        setError(null);
        try {
            await logout();
            window.location.reload();
            call.end();
        } catch (error: any) {
            setError(error);
        }
    };

    const resend = async () => {
        setIsLoading.toTrue();
        setError(null);

        try {
            const { message } = await api.post(userAPI.resendVerificationEmailURL());
            setMessage(message);
            await refreshUser();
        } catch (error: any) {
            setError(error);
        } finally {
            setIsLoading.toFalse();
        }
    };

    return (
        <Modal isOpen size="small" padding>
            <h2>Bekreft din e-postadresse</h2>
            <div className={style.confirmEmail}>
                {message ? <p>{message}</p> : <p>Vi har sendt deg en e-post med en bekreftelseslenke.</p>}
                <p>Vennligst klikk på lenken for å bekrefte e-postadressen.</p>
                <SpinnerContainer isSpinning={isLoading}>
                    <ErrorBox errors={error} />
                    <div className={style.buttons}>
                        <p>Har du ikke fått e-posten?</p>
                        <button className="btn-secondary" onClick={resend}>
                            Bestill ny lenke
                        </button>
                        <p>Passer det dårlig akkurat nå?</p>
                        <button className="btn-secondary" onClick={logOut}>
                            Logg ut
                        </button>
                    </div>
                </SpinnerContainer>
            </div>
        </Modal>
    );
});

export default ConfirmEmailModal;
