import { useContext, useRef, useState } from 'react';
import { createCallable } from 'react-call';
import { useDebounceCallback } from 'usehooks-ts';
import { commentProduct, rateProduct, UserData } from '../api/product';
import ErrorBox, { ErrorBoxType } from '../generic/ErrorBox';
import Modal from '../generic/Modal';
import Spinner from '../generic/Spinner';
import { TrackingContext } from '../generic/TrackingContext';
import ReviewStars from '../product/ReviewStars';
import useBooleanState from '../utils/useBooleanState';
import ReviewComment from './ReviewComment';

interface Props {
    productCode: string;
    rating: number | undefined;
    comment: string;
    onUserDataChange(userData: UserData): void;
}

const ProductReviewModal = createCallable<Props>(({ call, rating, comment, productCode, onUserDataChange }) => {
    const { item_list_id, item_list_name } = useContext(TrackingContext);
    const [isLoading, setLoading] = useBooleanState();
    const [error, setError] = useState<ErrorBoxType | null>(null);
    const hasTrackedComment = useRef(false);

    const saveRating = useDebounceCallback((rating: number) => {
        setLoading.toTrue();
        setError(null);
        gtag('event', 'rating', {
            stars: rating,
            item_id: productCode,
            item_list_id,
            item_list_name,
        });
        rateProduct(productCode, { rating: rating }).then(onUserDataChange).catch(setError).finally(setLoading.toFalse);
    }, 500);

    const saveComment = useDebounceCallback((newComment: string) => {
        setLoading.toTrue();
        setError(null);

        if (!hasTrackedComment.current) {
            hasTrackedComment.current = true;
            gtag('event', 'rating', {
                comment: true,
                item_id: productCode,
                item_list_id,
                item_list_name,
            });
        }
        commentProduct(productCode, { comment: newComment }).then(onUserDataChange).catch(setError).finally(setLoading.toFalse);
    }, 500);

    return (
        <Modal isOpen={true} padding size="small" onClose={call.end}>
            <h3>Min vurdering</h3>

            <form className="product-review">
                <ReviewStars productCode={productCode} rating={rating || 0} onSelectStar={saveRating} />
                <ReviewComment productCode={productCode} comment={comment} onChange={saveComment} />
            </form>
            <Spinner isSpinning={isLoading} />
            <ErrorBox errors={error} />
        </Modal>
    );
});

export default ProductReviewModal;
