import { PropsWithChildren, createContext, useContext, useMemo } from 'react';

export interface TrackingContext {
    item_id?: string;
    items?: {
        item_id: string;
        index: number;
        quantity: number;
    }[];
    index?: number;
    quantity?: number;
    item_list_name?: string;
    item_list_id?: string;
    filter_type?: string;
    value?: number;
    currency?: string;
    tab_name?: string;
    context?: string;
}

export const TrackingContext = createContext<TrackingContext>({});

interface WrapTrackingContextProps {
    set: TrackingContext;
}

export function WrapTrackingContext({ children, set: wrapperData }: PropsWithChildren<WrapTrackingContextProps>) {
    const data = useContext(TrackingContext);

    const value = useMemo<TrackingContext>(
        () => ({
            ...data,
            ...wrapperData,
        }),
        [data, JSON.stringify(wrapperData)],
    );

    return <TrackingContext.Provider value={value}>{children}</TrackingContext.Provider>;
}
