import { useState } from 'react';

interface Props {
    productCode: string;
    rating: number;
    onSelectStar(rating: number): void;
}

export default function ReviewStars({ productCode, rating, onSelectStar }: Props) {
    const [ratingState, setRating] = useState(rating ?? 0);

    const onClick = (newRating: number) => {
        if (newRating === ratingState) {
            onSelectStar(0);
            setRating(0);
        }
    };

    const onChange = (newRating: number) => {
        setRating(newRating);

        if (newRating !== ratingState) {
            onSelectStar(newRating);
        }
    };
    return (
        <div className="product-item__rating-stars" role="radiogroup" aria-labelledby={`${productCode}_label`}>
            <span id={`${productCode}_label`} className="sr-only">
                Gi produkt opp til 5 stjerner.
            </span>
            <Star stars={5} productCode={productCode} rating={ratingState} onChange={onChange} onClick={onClick} />
            <Star stars={4} productCode={productCode} rating={ratingState} onChange={onChange} onClick={onClick} />
            <Star stars={3} productCode={productCode} rating={ratingState} onChange={onChange} onClick={onClick} />
            <Star stars={2} productCode={productCode} rating={ratingState} onChange={onChange} onClick={onClick} />
            <Star stars={1} productCode={productCode} rating={ratingState} onChange={onChange} onClick={onClick} />
        </div>
    );
}

function Star({
    stars,
    productCode,
    rating,
    onChange,
    onClick,
}: {
    stars: number;
    productCode: string;
    rating: number;
    onChange: (rating: number) => void;
    onClick: (rating: number) => void;
}) {
    return (
        <>
            <input
                tabIndex={0}
                type="radio"
                id={`${productCode}_star${stars}`}
                aria-label={`${stars} stjerner`}
                name="rating"
                value={stars}
                checked={stars == rating}
                onClick={() => onClick(stars)}
                onChange={() => onChange(stars)}
            />
            <label className="product-item__star" htmlFor={`${productCode}_star${stars}`} />
        </>
    );
}
