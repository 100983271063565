import { useState } from 'react';
import eventTargetValue from '../utils/eventTargetValue';

interface Props {
    productCode: string;
    comment: string;
    onChange(comment: string): void;
}

export default function ReviewComment({ productCode, comment, onChange }: Props) {
    const [commentState, setComment] = useState(comment ?? '');

    const handleChange = (comment: string) => {
        setComment(comment);
        onChange(comment);
    };

    return (
        <>
            <textarea
                className="product-review__comment form-control"
                id={`${productCode}_comment`}
                name="comment"
                placeholder="Skriv et notat"
                value={commentState}
                onChange={eventTargetValue(handleChange)}
            />
            <label className="sr-only" htmlFor={`${productCode}_comment`}>
                Skriv et notat
            </label>
        </>
    );
}
