export default function salesforceChat(isLoggedIn, user) {
  function initESW(gslbBaseURL) {
    window.embedded_svc.settings.displayHelpButton = true;
    window.embedded_svc.settings.language = 'no';

    window.embedded_svc.settings.defaultMinimizedText = 'Chat med oss';
    window.embedded_svc.settings.disabledMinimizedText = 'Ingen kundebehandler tilgjengelig';
    window.embedded_svc.settings.loadingText = 'Laster...';

    // prefill name and email if user is logged in
    if (isLoggedIn) {
        window.embedded_svc.settings.prepopulatedPrechatFields = {
            SuppliedName: `${user.firstName} ${user.lastName}`,
            SuppliedEmail: user.uid,
        };
    }

    window.embedded_svc.settings.offlineSupportMinimizedText = 'Kontakt oss'; //(Defaults to Contact Us)

    window.embedded_svc.settings.enabledFeatures = ['LiveAgent'];
    window.embedded_svc.settings.entryFeature = 'LiveAgent';
    window.embedded_svc.settings.extraPrechatInfo = [
      {
        "entityFieldMaps": [
          { "doCreate": false, "doFind": true, "fieldName": "LastName", "isExactMatch": true, "label": "Etternavn" },
          { "doCreate": false, "doFind": true, "fieldName": "FirstName", "isExactMatch": true, "label": "Fornavn" },
          { "doCreate": false, "doFind": true, "fieldName": "Email", "isExactMatch": true, "label": "E-post" }
        ],
        "entityName": "Contact",
        "saveToTranscript": "Contact",
        "showOnCreate": true
      }
    ];
    window.embedded_svc.settings.extraPrechatFormDetails =
      [
        {
          "label": "Navn",
          "transcriptFields": ["Visitor_Name__c"],
          "displayToAgent": true
        },
        {
          "label": "Emne",
          "transcriptFields": ["Subject__c"],
          "displayToAgent": true
        }
      ];


    window.embedded_svc.addEventHandler("onHelpButtonClick", function() {
      gtag('event', 'livechat', {
        action: 'ready',
        state: 'maximized'
      });
    });

    window.embedded_svc.init(
      'https://vinmonopolet.my.salesforce.com',
      'https://vinmonopolet.secure.force.com/liveAgentSetupFlow',
      gslbBaseURL,
      '00D5I000000EdhI',
      'Chat_Group',
      {
        baseLiveAgentContentURL: 'https://c.la2-c2-cdg.salesforceliveagent.com/content',
        deploymentId: '5725I000000H5UC',
        buttonId: '5735I000000LHjH',
        baseLiveAgentURL: 'https://d.la2-c2-cdg.salesforceliveagent.com/chat',
        eswLiveAgentDevName: 'Chat_Group',
        isOfflineSupportEnabled: true
      }
    );
  }

  var s = document.createElement('script');
  s.setAttribute('src', 'https://vinmonopolet.my.salesforce.com/embeddedservice/5.0/esw.min.js');
  s.onload = function () {
    initESW(null);
  };
  document.body.appendChild(s);
}